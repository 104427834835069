/**
 * 浮点数加减乘除
 */
/**
 * 加法
 * @param {number} value1
 * @param {number} value2
 * @returns {number}
 */
export function add(value1, value2) {
  var r1, r2, m, c;
  try {
    r1 = value1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = value2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      value1 = Number(value1.toString().replace(".", ""));
      value2 = Number(value2.toString().replace(".", "")) * cm;
    } else {
      value1 = Number(value1.toString().replace(".", "")) * cm;
      value2 = Number(value2.toString().replace(".", ""));
    }
  } else {
    value1 = Number(value1.toString().replace(".", ""));
    value2 = Number(value2.toString().replace(".", ""));
  }
  return (value1 + value2) / m;
}

/**
 * 减法
 * @param {number} value1
 * @param {number} value2
 * @returns {number}
 */
export function Subtr(value1, value2) {
  var r1, r2, m, n;
  try {
    r1 = value1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = value2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  // 动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((value1 * m - value2 * m) / m).toFixed(n);
}

/**
 * 乘法
 * @param {number} value1
 * @param {number} value2
 * @returns {number}
 */
export function multiple(value1, value2) {
  var m = 0,
    s1 = value1.toString(),
    s2 = value2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

/**
 * 除法
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number}
 */
export function division(arg1, arg2) {
  let t1 = 0;
  let t2 = 0;
  let r1;
  let r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    console.log("division", e);
  }
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    console.log("division", e);
  }
  // eslint-disable-next-line prefer-const
  r1 = Number(arg1.toString().replace(".", ""));
  // eslint-disable-next-line prefer-const
  r2 = Number(arg2.toString().replace(".", ""));
  // eslint-disable-next-line no-undef
  return (r1 / r2) * Math.pow(10, t2 - t1);
}
